export const formData = (data: any, type?: 'ADD' | 'EDIT') => {
	const bodyFormData = new FormData();

	for (const key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key)) {
			if (
				type === 'ADD'
					? data[key] !== undefined && data[key] !== null && data[key] !== ''
					: data[key] !== undefined && data[key] !== null
			) {
				if (Array.isArray(data[key])) {
					if (data[key].length === 0) {
						bodyFormData.append(key, '[]');
					}

					data[key].forEach((val: any, index: number) => {
						if (typeof val === 'object' && val !== null) {
							for (const innerKey in val) {
								if (
									type === 'ADD'
										? val[innerKey] !== undefined &&
										  val[innerKey] !== null &&
										  val[innerKey] !== ''
										: val[innerKey] !== undefined && val[innerKey] !== null
								) {
									bodyFormData.append(key + `[${index}]` + `[${innerKey}]`, val[innerKey]);
								}
							}
						} else {
							bodyFormData.append(key + `[${index}]`, val);
						}
					});
				} else {
					bodyFormData.append(key, data[key]);
				}
			}
		}
	}

	return bodyFormData;
};

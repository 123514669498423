import {Spin} from 'antd';

interface LoaderProps {
	size?: 'large' | 'default' | 'small' | null;
	tip?: string | null;
}

const Loading = ({size, tip}: LoaderProps) => {
	return (
		<div
			style={{
				position: 'absolute',
				width: '100vw',
				height: '100vh',
				top: 0,
				left: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
			<Spin size={size ?? 'default'} tip={tip ?? ''} />
		</div>
	);
};

export default Loading;
